import React from "react"
import {
  GridBox,
  Standalone,
  Text,
  Title,
} from "@thedgbrt/logology-ui-components"
import SEO from "../../components/seo"

const AffiliatePage = () => (
  <>
    <SEO title="Become an affiliate" />
    <GridBox type={5} colSpans={[[3, 8], [3, 8], [3, 9]]} withBgContainer>
      <Title standalone>Become an affiliate</Title>
    </GridBox>

    <GridBox type={5} colSpans={[[4, 8], [4, 8], [3, 7]]} withBgContainer>
      <Standalone>
        <Text italic>
          We offer 30% commission on sales of our start and grow packages. You
          can <a href="https://logology.getrewardful.com/">signup here</a> to
          get your unique link.
        </Text>
        <Text italic>
          If you have questions or want to discuss a different type of
          partnership, you can reach us at{" "}
          <a href="mailto:team@logology.co">team@logology.co</a>.
        </Text>
      </Standalone>
    </GridBox>
  </>
)

export default AffiliatePage
